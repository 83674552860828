import React from "react"
import queryString from "query-string"
import {useStaticQuery, graphql} from "gatsby"

import Card from "../../components/card"
import { FormFieldInput, FormFieldTextArea, FormFieldDropdown, FormFieldFile  } from "../../components/form-fields"

import ApplicationFormLogic from "../../components/application-form-logic"
import ApplicationFormContainer from "../../components/application-form-container"

export class DriehausAwardForm extends ApplicationFormLogic {
  render() {
    return (
      <ApplicationFormContainer
        data={this.props.data}
        state={this.state}
        closeModal={this.closeModal}
        handleSubmit={this.handleSubmit}
        handleSave={this.handleSave}
        >
        <Card sectionTitle="Project" sectionSubtitle="">
          <FormFieldInput
            labelText="Project Name"
            name="projectName"
            type="text"
            value={this.state.projectName}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
            required="required"
          />

          <FormFieldTextArea
            labelText="Location"
            name="projectLocation"
            value={this.state.projectLocation}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
            helpText="The address or general location of your project."
          />

          <FormFieldInput
            labelText="Architect"
            name="projectArchitect"
            type="text"
            value={this.state.projectArchitect}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />

          <FormFieldInput
            labelText="Sponsor or Owner"
            name="projectSponsor"
            type="text"
            value={this.state.projectSponsor}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />

          <FormFieldDropdown
            labelText="Project Development"
            name="projectDevelopment"
            value={this.state.projectDevelopment}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          >
            <option value="">Click to select an option</option>
            <option value="New construction">New construction</option>
            <option value="Rehabilitation/renovation of an existing site">Rehabilitation/renovation of an existing site</option>
          </FormFieldDropdown>

          <FormFieldInput
            labelText="Construction Cost"
            name="projectCost"
            type="text"
            value={this.state.projectCost}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />

          <FormFieldDropdown
            labelText="Type of Project"
            name="projectType"
            value={this.state.projectType}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          >
            <option value="">Click to select an option</option>
            <option value="Housing">Housing</option>
            <option value="Commercial">Commercial</option>
            <option value="Community facility">Community facility (e.g. school, health care center, daycare, library, church, etc.)</option>
            <option value="Complex">Complex (e.g. school campus, town center, park, etc.)</option>
          </FormFieldDropdown>

          <FormFieldInput
            labelText="Square Footage"
            name="projectFootage"
            type="text"
            value={this.state.projectFootage}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />

          <FormFieldDropdown
            labelText="This project is located in a low- or moderate-income
                      area or it serves a low- or moderate-income population."
            name="projectPopulation"
            value={this.state.projectPopulation}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          >
            <option value="">Click to select an option</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </FormFieldDropdown>

          <FormFieldTextArea
            labelText="Project Description (3 sentences or less)"
            name="projectDescription"
            value={this.state.projectDescription}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />

          <FormFieldDropdown
            labelText="This project included a community planning process."
            name="projectProcessBool"
            value={this.state.projectProcessBool}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          >
            <option value="">Click to select an option</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </FormFieldDropdown>

          <FormFieldTextArea
            labelText="Please describe the community planning process if it was included."
            name="projectProcess"
            value={this.state.projectProcess}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />

          <FormFieldTextArea
            labelText="How does the project's architectural design respond to
                      the neighborhood context?"
            name="projectDesignNeighborhood"
            value={this.state.projectDesignNeighborhood}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />

          <FormFieldTextArea
            labelText="How does the project's architectural design respond to
                      those served by the facility?"
            name="projectDesignServed"
            value={this.state.projectDesignServed}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />

          <FormFieldTextArea
            labelText="How does the project's architectural design enhance the
                      overall development of the neighborhood?"
            name="projectDesignDevelopment"
            value={this.state.projectDesignDevelopment}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />

          <FormFieldTextArea
            labelText="How does the project's architectural design utilize
                      environmentally sustainable materials or systems?"
            name="projectSustainable"
            value={this.state.projectSustainable}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />

        </Card>


        <Card sectionTitle="Media" sectionSubtitle={this.props.data.mediaSectionSubhead}>

          <FormFieldFile
            labelText="Supporting Photos"
            handleProcessFile={this.handleProcessFile}
            handleProcessFiles={this.handleProcessFiles}
            handleRemoveFile={this.handleRemoveFile}
            files={this.state.files}
            disabled={this.state.submitted}
            name="uploadPhotos"
            helpText="Upload eight photos (four exterior, four interior) of the project. Only image files (.png, .jpg, etc.) are accepted. Images must be a maximum of 5MB in size."
            required="required"
          />

          <FormFieldFile
            labelText="Project Budget"
            allowMultiple={false}
            maxFiles={1}
            acceptedFileTypes={[
              'application/pdf',
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            ]}
            handleProcessFile={this.handleProcessBudget}
            handleRemoveFile={this.handleRemoveBudget}
            files={this.state.budget}
            disabled={this.state.submitted}
            name="budget"
            helpText="Only PDF, .xlsx, and .docx files are accepted. Files must be a maximum of 5MB in size."
            required="required"
          />

          <FormFieldFile
            labelText="Site and Floor Plans"
            allowMultiple={true}
            maxFiles={2}
            acceptedFileTypes={['image/*', 'application/pdf']}
            handleProcessFile={this.handleProcessFile}  // Push uploaded files to a buffer
            handleProcessFiles={this.handleProcessPlans}
            handleRemoveFile={this.handleRemovePlan}
            files={this.state.plan}
            disabled={this.state.submitted}
            name="plan"
            helpText="Only image files and PDFs are accepted. Files must be a maximum of 5MB in size."
            required="required"
          />

          <FormFieldFile
            labelText="Letter of Community Support"
            allowMultiple={false}
            maxFiles={1}
            acceptedFileTypes={[
              'application/pdf',
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            ]}
            handleProcessFile={this.handleProcessLetter}
            handleRemoveFile={this.handleRemoveLetter}
            files={this.state.letter}
            disabled={this.state.submitted}
            name="letter"
            helpText={
              <>
                <span style={{marginBottom: '1rem'}}>
                  Only PDFs and .docx files are accepted. Please upload a community
                  support letter that provides contact information in addition to
                  answers to the following questions:
                </span>
                <ol style={{paddingTop: '1rem'}}>
                  <li>Does the project benefit your neighborhood? If so, how?</li>
                  <li>What role did your organization play in the design process, if any?</li>
                  <li>Were community resources utilized in the planning and construction of the project? If so, how?</li>
                </ol>
              </>
            }
            required="required"
          />

        </Card>

        <Card sectionTitle="Contact" sectionSubtitle="">
          <FormFieldInput
            labelText="Name"
            name="applicantName"
            type="text"
            value={this.state.applicantName}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
            required="required"
          />

          <FormFieldInput
            labelText="Organization"
            name="applicantOrganization"
            type="text"
            value={this.state.applicantOrganization}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
            required="required"
          />

          <FormFieldInput
            labelText="Title"
            name="applicantTitle"
            type="text"
            value={this.state.applicantTitle}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />

          <FormFieldTextArea
            labelText="Address"
            name="applicantAddress"
            type="text"
            value={this.state.applicantAddress}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />

          <FormFieldInput
            labelText="Phone Number"
            name="applicantPhone"
            type="text"
            value={this.state.applicantPhone}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
            required="required"
          />

          <FormFieldInput
            labelText="Email"
            name="applicantEmail"
            type="text"
            value={this.state.applicantEmail}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
            required="required"
          />

          <FormFieldInput
            labelText="How did you learn about the opportunity to apply for this award?"
            name="applicantReferral"
            type="text"
            value={this.state.applicantReferral}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />
        </Card>
      </ApplicationFormContainer>
    );
  }
}

export default props => {
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: {eq: "applications/driehaus-award.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 1000, maxHeight: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  data.applicationType="driehaus-award"
  data.fields=[
    'projectName', 'projectLocation', 'projectArchitect', 'projectSponsor',
    'projectDevelopment', 'projectCost', 'projectType', 'projectFootage',
    'projectPopulation', 'projectDescription', 'projectProcessBool',
    'projectProcess','projectDesignNeighborhood', 'projectDesignServed',
    'projectDesignDevelopment', 'projectSustainable',
    'applicantName', 'applicantOrganization', 'applicantTitle',
    'applicantAddress', 'applicantPhone', 'applicantEmail', 
    'applicantReferral'
  ]

  data.title="Driehaus Award"
  data.titleText="The Richard H. Driehaus Foundation Award for Architectural Excellence in Community Design"
  data.awardDescription="The Richard H. Driehaus Foundation Award for Architectural Excellence in Community Design was established to recognize the importance of great architecture and craftsmanship to city life."
  data.awardLink="https://lisc-cnda.org/awards/driehaus-award"
  data.headerImagePath="applications/driehaus-award.jpg"
  data.headerImageLink="https://map.lisc-cnda.org/2018/driehaus/casa-queretaro"
  data.headerImageProject="Casa Queretaro"
  data.headerImageDescription=", a 2018 Driehaus Award winner"
  data.mediaSectionSubhead="Required: 8 photos (four exterior, four interior), a project budget, project site and floor plans, and a letter of support."

  return (
    <DriehausAwardForm
      data={data}
      uuid={queryString.parse(props.location.search).uuid}
  />
  )
}
